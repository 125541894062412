import stickernormal from './Media/Stickers/Sticker normal.png'
import stickergitd from './Media/Stickers/Stickergitd.png'
import './App.css';
import CookieConsent from "react-cookie-consent";
function App() {
  return (
    <>
<header>
    <div class="container">
      <nav>
        <div class="logo">Milde Muis</div>
        <ul class="menu">
          <li><a href="#home">Home</a></li>
          <li><a href="#hoodies">Hoodies</a></li>
          <li><a href="#tshirts">T-Shirts</a></li>
        </ul>
      </nav>
    </div>
  </header>

  <main class="container">
    <h1>Welcome to Milde Muis</h1>
    <p>We are still setting up the webshop all orders go via Skydive supplies!  Click on the buttons to be escorted to Their webpage</p>

        <section id="hoodies">
      <h2>Clothing</h2>
      <div class="products">
        <div class="product">
          <img src={"https://cdn.skydivesupplies.nl/uploads/tx_multishop/images/products/original/mil/milde-muis-hoodie.png?rs=w:717,h:452/qt=q:80"} alt="Milde Muis hoodie"></img>
          <div class="product-info">
            <h3 class="product-title">Milde Muis hoodie</h3>
            <p class="product-price">€34,95</p>
            <button class="button"> <a style={{textdecoration:"none" , color:"inherit"}}     href="https://www.skydivesupplies.nl/skydiveproducten/kleding/milde-muis-hoodie"> Click Here </a></button>
          </div>
        </div>
        <div class="product">
          <img src="https://cdn.skydivesupplies.nl/uploads/tx_multishop/images/products/original/mil/milde-muis-t-shirt.png?rs=w:717,h:452/qt=q:80" alt="Muis T-Shirt 1"></img>
          <div class="product-info">
            <h3 class="product-title">Milde Muis T-shirt</h3>
            <p class="product-price">€ 19,95</p>
              <button class="button"><a style={{textdecoration:"none" , color:"inherit"}}  href="https://www.skydivesupplies.nl/skydiveproducten/kleding/milde-muis-t-shirt"> Click Here</a></button>
          </div>
        </div>
      </div>
    </section>

    <section id="tshirts">
      <h2>Awesome Stickers</h2>
      <div class="products">
       <div class="product">
          <img src={stickernormal} alt="Muis Sticker"></img>
          <div class="product-info">
            <h3 class="product-title">Milde Muis Stickers 12cm x 4.4cm 5pack</h3>
            <p>Limited stock</p>
            <p class="product-price">€ 3,02	(excl btw)</p>
            <button class="button"><a style={{textdecoration:"none" , color:"inherit"}}  href='mailto:jordi@mildemuis.nl'>Order and info via Email</a></button>
         </div>
        </div>
        <div class="product">
          <img src={stickergitd} alt="Muis Sticker GITD"></img>
          <div class="product-info">
            <h3 class="product-title">Milde Muis Stickers Glow in the dark 12cm x 4.4cm 5pack</h3>
            <p>Limited stock</p>
            <p class="product-price">€ 7,23	(excl btw) </p>
            <button class="button"><a style={{textdecoration:"none" , color:"inherit"}} href='mailto:jordi@mildemuis.nl'>Order and info via Email</a></button>
         </div>
        </div>
        <div class="product">
          <img src={stickernormal} alt="Muis Sticker"></img>
          <div class="product-info">
            <h3 class="product-title">Milde Muis Stickers 8cm x 3.0cm 5pack</h3>
            <p>Limited stock</p>
            <p class="product-price">€ 1,98		(excl btw)</p>
            <button class="button"><a style={{textdecoration:"none" , color:"inherit"}} href='mailto:jordi@mildemuis.nl'>Order and info via Email</a></button>
         </div>
        </div>
        
      </div>
      
    </section>
  </main>

  <footer>
    <div class="container footer-content">
      <div class="footer-section">
        <h3>Quick Links</h3>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#hoodies">Hoodies</a></li>
          <li><a href="#tshirts">T-Shirts</a></li>
        </ul>
      </div>
      <div class="footer-section">
        <h3>Customer Service</h3>
        <ul>
          <li>KVK: 91461057</li>
          <li><a href="mailto:jordi@mildemuis.nl">Contact Us</a></li>
        </ul>
      </div>
    </div>
    <CookieConsent
  location="bottom"
  buttonText="Aight"
  cookieName="MildeMuiscookie"
  style={{ background: "#2B373B" }}
  buttonStyle={{ color: "#4e503b", fontSize: "14px" }}
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
</CookieConsent>
  </footer>




    </>
  );
}

export default App;
